<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="ventas"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          class="elevation-1"
          show-expand
          single-expand
          item-key="idVenta"
          @item-expanded="whenExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>MIS VENTAS</v-toolbar-title>
                <v-toolbar-title class="subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Contado</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="subtitle-1" v-if="$vuetify.breakpoint.mdAndUp">Contado</v-toolbar-title>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="nuevaVenta = !nuevaVenta">
                Nueva Venta
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex flex-column">
              <v-row>
                <v-col cols="12" sm="12" class="pb-0">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    outlined
                    dense
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaInicio"
                    v-model="modalFechaTablaInicio"
                    :return-value.sync="fechaTablaInicio"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaInicio"
                        label="Fecha de Inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaInicio" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaInicio = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaInicio()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-dialog
                    ref="dialogFechaTablaFin"
                    v-model="modalFechaTablaFin"
                    :return-value.sync="fechaTablaFin"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateadaFin"
                        label="Fecha Final"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        outlined
                        dense
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaTablaFin" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaTablaFin = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFechaFin()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  class="d-flex justify-end"
                  v-if="getInfoUsuario.rol != 'Usuario'"
                >
                  <v-btn
                    outlined
                    :dark="!!ventas.length"
                    :disabled="!ventas.length"
                    class="mx-4"
                    color="green"
                    @click="exportToExcel"
                  >
                    <v-icon left>mdi-file-excel</v-icon>Exportar
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.costoVentaTotal="{ item }">
            <v-chip :color="getEstado(item.costoVentaTotal)" dark>
              S/ {{
              item.costoVentaTotal.toFixed(2)
              }}
            </v-chip>
          </template>
          <template v-slot:item.fechaVenta="{ item }">{{ formatDateTable(item.fechaVenta) }}</template>
          <template v-slot:expanded-item="{ headers, item }" style="width: 100%">
            <td :colspan="headers.length" v-if="expandedLoading" class="px-0">
              <v-skeleton-loader ref="expSkeleton" type="article" class="mx-auto"></v-skeleton-loader>
            </td>
            <td :colspan="headers.length" v-else class="px-0 grey lighten-4">
              <div v-if="Object.keys(detalleVenta).length">
                <v-container fluid>
                  <v-layout>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="12" class="pb-0">
                        <div class="d-flex justify-space-around">
                          <p>
                            Productos Vendidos:
                            <span>{{item.cantidadVendidos}}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-data-table
                          dense
                          :items="detalleVenta.productos"
                          :headers="headersListaProductos"
                          item-key="idProducto"
                          hide-default-footer
                        >
                          <template v-slot:item.costoUnitario="{ value }">S/ {{value.toFixed(2)}}</template>
                          <template
                            v-slot:item.costoTotalProducto="{ value }"
                          >S/ {{value.toFixed(2)}}</template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-snackbar :value="isError" color="error">
        {{ isError ? getError.errorMessage : '' }}
        <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
      </v-snackbar>
      <v-snackbar :value="infoSnackbar" color="success">
        {{ infoSnackbar ? infoSnackbarMessage : '' }}
        <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
      </v-snackbar>
      <v-dialog v-model="nuevaVenta" scrollable fullscreen transition="dialog-bottom-transition">
        <v-form ref="formVentaContado" v-model="validVentaContadoForm" lazy-validation>
          <v-card>
            <v-card-title class="my-auto teal darken-3 white--text">
              <span class="title">Nueva Venta al Contado</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-0">
              <v-container fluid class="py-0">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <div class="d-flex flex-row justify-center align-self-center ma-2 mb-4">
                      <v-autocomplete
                        label="Cliente"
                        placeholder="Selecciona un Cliente"
                        v-model="venta.idCliente"
                        class="mt-6 flex-grow-1"
                        :rules="noVacioRules"
                        :items="getListaClientes"
                        item-text="nombreCliente"
                        item-value="idCliente"
                        prepend-icon="mdi-briefcase-account"
                        outlined
                        dense
                        required
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombreCliente"></v-list-item-title>
                            <v-list-item-subtitle v-text="data.item.direccion"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div class="d-flex ma-2">
                      <v-text-field
                        class="flex-shrink-1"
                        label="Detalle de la Venta"
                        v-model="venta.detalle"
                        prepend-inner-icon="mdi-pencil"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </div>
                    <div class="d-flex flex-row justify-space-between">
                      <p class="title black--text font-weight-bold my-auto">Producto(s)</p>
                      <v-btn dark color="base" class="mx-2" @click="agregarProducto">
                        Agregar
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="d-flex flex-column mx-auto mt-4" v-if="venta.productos">
                    <template v-if="venta.productos.length">
                      <div
                        class="d-flex flex-column my-2 grey lighten-4 flex-grow-0 flex-shrink-1"
                        v-for="(item, index) in venta.productos"
                        :key="index"
                      >
                        <div class="d-flex justify-space-between">
                          <p class="font-weight-bold mx-4 mt-2 my-0">{{index+1}}.</p>
                          <v-btn text color="red" @click="eliminarProducto(index)">eliminar</v-btn>
                        </div>
                        <div class="d-flex mx-2 my-1">
                          <v-autocomplete
                            label="Producto"
                            placeholder="Selecciona un Producto"
                            v-model="venta.productos[index].idProducto"
                            class="flex-shrink-1 mx-2"
                            :items="getListaProductosFiltrada"
                            item-text="nombreProducto"
                            item-value="idProducto"
                            :rules="noVacioRules"
                            @change="definirPrecioxCliente(index)"
                            outlined
                            dense
                            required
                          ></v-autocomplete>
                          <v-text-field
                            class="mx-2 flex-grow-1"
                            label="Cantidad"
                            v-model="venta.productos[index].cantidad"
                            :rules="cantidadRules"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                        <div class="d-flex mx-2 my-1">
                          <v-text-field
                            class="mx-2 flex-shrink-1"
                            label="Precio/unidad"
                            prefix="S/"
                            v-model="venta.productos[index].costoUnitario"
                            :rules="cantidadDecimalRules"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </div>
                    </template>
                    <p
                      v-else
                      class="subtitle-1 text-center mt-4"
                    >Aquí aparecen los productos agregados a la venta</p>
                    <div class="d-flex justify-center ma-2">
                      <v-text-field
                        class="mx-auto flex-shrink-0 flex-grow-0"
                        label="Pago con Billete"
                        prefix="S/"
                        v-model="billete"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                    <div class="d-flex flex-row justify-space-around py-0">
                      <p class="text--black font-weight-bold title">Monto Total</p>
                      <p class="text--black font-weight-medium headline">S/ {{ getMontoTotal }}</p>
                    </div>
                    <div
                      class="d-flex justify-space-around py-0"
                      v-if="billete - getMontoTotal > 0"
                    >
                      <p class="text--black font-weight-bold title">Vuelto</p>
                      <p
                        class="text--black font-weight-medium headline"
                      >S/ {{(billete - getMontoTotal).toFixed(2)}}</p>
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text :disabled="isLoading" @click="nuevaVenta = false">Cerrar</v-btn>
              <v-btn text :disabled="isLoading" @click="limpiarFormulario">
                <v-icon left>mdi-refresh</v-icon>Limpiar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                :loading="isLoading"
                dark
                @click.prevent="submitVentaContado"
              >Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import XLSX from 'xlsx'

export default {
  components: {},
  data: vm => ({
    verificado: true,
    validVentaContadoForm: false,
    noVacioRules: [v => !!v || 'Debes seleccionar un item de la lista'],
    cantidadDecimalRules: [
      v => (v && v > 0) || 'Debes insertar una cantidad mayor a 0'
    ],
    cantidadRules: [
      v => (v && v > 0) || 'Debes insertar una cantidad mayor a 0',
      v => (v && v % 1 == 0) || 'Solo se admiten números enteros'
    ],
    headers: [
      { text: 'Venta #', value: 'idVenta', align: 'center' },
      {
        text: 'Vendedor',
        value: 'nombreVendedor',
        align: 'center'
      },
      { text: 'Cliente', value: 'nombreCliente', align: 'center' },
      { text: 'Detalle de Venta', value: 'detalleVenta', align: 'center' },
      {
        text: 'Productos',
        value: 'productos',
        align: 'center'
      },
      {
        text: '# de Items Vendidos',
        value: 'cantidadVendidos',
        align: 'center'
      },
      {
        text: '# de Items Prestados',
        value: 'cantidadPrestados',
        align: 'center'
      },

      { text: 'Monto', value: 'costoVentaTotal', align: 'center' },
      { text: 'Fecha y Hora de Venta', value: 'fechaVenta', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    headersListaProductos: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Costo/u', value: 'costoUnitario', align: 'center' },
      { text: 'Cantidad', value: 'cantidad', align: 'center' },
      { text: 'Costo Total', value: 'costoTotalProducto', align: 'center' }
    ],
    ventas: [],
    dataIsloading: false,
    infoSnackbar: false,
    infoSnackbarMessage: '',
    buscarTabla: '',
    fechaTablaInicio: new Date(new Date().setHours(-5))
      .toISOString()
      .substr(0, 10),
    fechaTablaFin: new Date(new Date().setHours(24))
      .toISOString()
      .substr(0, 10),
    modalFechaTablaInicio: false,
    modalFechaTablaFin: false,
    nuevaVenta: false,
    venta: {
      idCliente: '',
      costoVentaTotal: 0,
      fechaVenta: new Date().getTime(),
      esVentaCredito: false,
      productos: [
        {
          idProducto: '',
          cantidad: 0,
          costoUnitario: 0,
          costoTotalProducto: 0,
          prestamoProducto: 0
        }
      ]
    },
    productos: [],
    clientes: [],
    billete: 0,
    expandedLoading: false,
    detalleVenta: {}
  }),
  methods: {
    getEstado(venta) {
      if (venta >= 300) return 'red'
      else if (venta >= 150) return 'yellow'
      else return 'green'
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    async submitVentaContado() {
      this.validVentaContadoForm = false
      if (this.$refs.formVentaContado.validate()) {
        let response = await this.crearVenta(this.cleanSource(this.venta))
        if (response) {
          this.infoSnackbar = true
          this.infoSnackbarMessage =
            'Se ha creado una nueva venta con ID ' + response
          this.nuevaVenta = false
          this.venta = {
            idCliente: '',
            costoVentaTotal: 0,
            fechaVenta: new Date().getTime(),
            esVentaCredito: false,
            detalle: '',
            productos: [
              {
                idProducto: '',
                cantidad: 0,
                costoUnitario: 0,
                costoTotalProducto: 0,
                prestamoProducto: 0
              }
            ]
          }
          this.actualizarTabla()
          setTimeout(() => {
            this.infoSnackbar = false
          }, 5000)
        } else {
          this.loadingRequest(false)
        }
      }
    },

    agregarProducto() {
      this.venta.productos.push({
        idProducto: '',
        cantidad: 0,
        costoUnitario: 0,
        costoTotalProducto: 0,
        prestamoProducto: 0
      })
    },

    eliminarProducto(index) {
      this.venta.productos.splice(index, 1)
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    limpiarFormulario() {
      this.venta.productos = []
    },

    exportToExcel() {
      let resource = this.cleanSource(this.ventas)

      for (let i = 0; i < resource.length; i++) {
        resource[i].fechaVenta = this.formatDateTable(resource[i].fechaVenta)
      }

      let dataExported = XLSX.utils.json_to_sheet(resource, {
        header: [
          'idVenta',
          'idTrabajador',
          'nombreVendedor',
          'productos',
          'nombreCliente',
          'fechaVenta',
          'costoVentaTotal',
          'cantidadPrestados',
          'cantidadVendidos'
        ]
      })
      dataExported['A1'].v = 'ID de Venta'
      dataExported['B1'].v = 'ID de Trabajador'
      dataExported['C1'].v = 'Nombre de Vendedor'
      dataExported['D1'].v = 'Productos'
      dataExported['E1'].v = 'Cliente'
      dataExported['F1'].v = 'Fecha de Venta'
      dataExported['G1'].v = 'Monto Total'
      dataExported['H1'].v = '# de Items Prestados'
      dataExported['I1'].v = '# de Items Vendidos'

      const workbook = XLSX.utils.book_new()
      const filename = `${new Date().getTime()}-ventasContado`
      XLSX.utils.book_append_sheet(workbook, dataExported, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    async whenExpanded(row) {
      if (row.value) {
        this.expandedLoading = true
        this.rowSeleccionado = row
        let response = await this.fetchDetalleVenta(row.item.idVenta)
        this.detalleVenta = response
        this.expandedLoading = false
      }
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTablaInicio).setHours(24)
      let horaCeroFin = new Date(this.fechaTablaFin).setHours(24)
      let fechas = { horaCeroInicio, horaCeroFin }
      this.ventas = await this.fetchVentasContado(fechas)
    },

    actualizarFechaInicio() {
      this.$refs.dialogFechaTablaInicio.save(this.fechaTablaInicio)
      this.actualizarTabla()
    },

    actualizarFechaFin() {
      this.$refs.dialogFechaTablaFin.save(this.fechaTablaFin)
      this.actualizarTabla()
    },

    definirPrecioxCliente(index) {
      if (this.venta.idCliente) {
        let clienteSeleccionado = this.getListaClientes.filter(
          i => i.idCliente == this.venta.idCliente
        )
        let montoSeleccionado = 0
        let idProdSeleccionado = this.venta.productos[index].idProducto
        let filteredProductos = this.getListaProductosFiltrada.filter(
          i => i.idProducto == idProdSeleccionado
        )
        let nombreProdSeleccionado = filteredProductos[0].nombreProducto
        if (nombreProdSeleccionado.indexOf('10k') >= 0) {
          montoSeleccionado = clienteSeleccionado[0].precio10k
            ? clienteSeleccionado[0].precio10k
            : 0
        } else if (nombreProdSeleccionado.indexOf('45k') >= 0) {
          montoSeleccionado = clienteSeleccionado[0].precio45k
            ? clienteSeleccionado[0].precio45k
            : 0
        }
        this.venta.productos[index].costoUnitario = montoSeleccionado
      }
    },

    ...mapActions('user', ['fetchVentasContado', 'crearVenta']),
    ...mapMutations('user', ['setError', 'loadingRequest']),
    ...mapActions('venta', ['fetchDetalleVenta'])
  },
  computed: {
    computedFechaFormateadaInicio() {
      return this.formatDate(this.fechaTablaInicio)
    },

    computedFechaFormateadaFin() {
      return this.formatDate(this.fechaTablaFin)
    },

    getMontoTotal() {
      let monto = 0
      if (this.venta.productos.length) {
        let costoTotalProducto
        this.venta.productos.forEach(item => {
          costoTotalProducto = item.cantidad * item.costoUnitario
          item.costoTotalProducto = costoTotalProducto
          monto += costoTotalProducto
        })
        this.venta.costoVentaTotal = monto
      }

      return monto.toFixed(2)
    },

    getErrorMessage() {
      switch (this.getError.message) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.message
      }
    },

    ...mapGetters('user', [
      'isError',
      'getError',
      'isLoading',
      'getListaProductosFiltrada',
      'getListaClientes',
      'getInfoUsuario'
    ])
  },
  mounted: async function() {
    this.actualizarTabla()
  }
}
</script>

<style></style>
